.products
    text-align: center
    padding-bottom: 70px
    @media (max-width: 767px)
        padding-bottom: 30px 

    .products-list
        padding: 0
        margin-top: 40px
        margin-bottom: 90px
        @media (max-width: 767px)
            margin-bottom: 40px

        li
            list-style-type: none
            display: inline-block
            color: $brand-color
            font-size: 36px
            line-height: 46px
            @media (max-width: 767px)
                font-size: 20px
                line-height: 30px

            &:after
                content: "|"
                margin-left: 12px
                margin-right: 12px

            &:nth-last-child(1)
                &:after
                    display: none
    .example
        font-weight: 900
        font-size: 24px
        margin-top: 140px
        @media (max-width: 767px)
            font-size: 18px
            margin-top: 40px