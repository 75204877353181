.gallery
    padding: 0
    background-color: #0a2330

    .gallery-wrapper
        display: flex
        flex-wrap: wrap

        > div
            width: 25%
            @media (max-width: 767px)
                width: 50%

            img
                margin: 0 auto
         