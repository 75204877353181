.process
    padding: 0
    background-color: #f2f2f2
    display: flex
    flex-direction: row
    flex-wrap: wrap

    > div
        width: 50%
        height: 337px
        display: flex
        flex-direction: column
        @media (max-width:576px)
            width: 100%
            height: 250px
    
    .process-txt
        padding: 55px 70px
        justify-content: center
        padding-left: calc((100% - 1184px)/2 + 15px)
        @media (max-width: 991px)
            padding: 15px
        @media (min-width: 992px)
            padding-left: calc((100% - 984px)/2 + 15px)
        h2
            margin: 0 0 20px 0

        &:nth-child(4)
            padding: 55px 70px
            padding-right: calc((100% - 1184px)/2 + 15px)
            @media (max-width: 991px)
                padding: 15px
            @media (min-width: 992px)
                padding-right: calc((100% - 984px)/2 + 15px)

            p
                width: 80%

         &:nth-child(5)
            @media (max-width: 576px)
               order: 2

    .process-img
        background-repeat: no-repeat
        background-size: cover
        background-position: center

        &.one
            background-image: url("../img/process1.jpg")
            @media (max-width: 576px)
                order: -1

        &.two
            background-image: url("../img/process2.jpg")

        &.three
            background-image: url("../img/process3.jpg")