.description
    text-align: center

    .subtitle
        margin-top: 20px
        margin-bottom: 80px
        @media (max-width: 767px)
            margin-bottom: 40px

    .item
        @media (max-width: 767px)
            margin-bottom: 50px

        img
            margin: 0 auto
            @media (max-width: 767px)
                width: 70%

        p
            line-height: 25px

        .item-title
            font-weight: 700
            margin: 25px 0 0 0

