body
	font-family: 'Glober', sans-serif
	color: #0a2330
	
h1
	font-size: 90px
	color: #fff
	font-weight: 900
	@media (max-width: 991px)
		font-size: 60px
	@media (max-width: 767px)
		font-size: 40px
	
h2
	font-size: 72px
	font-weight: 900
	line-height: 82px
	@media (max-width: 767px)
		font-size: 34px
		line-height: 36px
	
h3
	font-size: 38px
	color: #fff
	@media (max-width: 1650px)
		font-size: 25px
	@media (max-width: 767px)
		font-size: 22px
	
h4
	font-size: 24px
	font-weight: 300
	margin-bottom: 45px
	@media (max-width: 767px)
		font-size: 18px
	
p
	font-size: 18px
	line-height: 37px
	margin: 0
	@media (max-width: 767px)
		font-size: 14px
		line-height: 24px

