section
	padding: 150px 0

	@media (max-width: 767px)
		padding: 30px 0
	@media (min-width: 992px) and (max-width: 1199px)
		padding: 50px 0	


header
	padding: 25px 0
	@media (max-width: 767px)
		padding: 10px 0
	img
		width: 170px
		@media (max-width:  767px)
			width: 90px	
		@media (min-width: 1199px)
			margin-left: 220px
		
footer
	background-color: #0a2330
	padding: 15px 0
	img
		width: 80px

.pleo-label
	position: fixed
	right: 0
	top: 50%
	width: 100px
	background-color: #0a2330
	border-top-left-radius: 5px
	border-bottom-left-radius: 5px
	padding: 13px
	box-sizing: border-box
	transform: translateY(-50%)

@font-face {
	font-family: 'Glober';
	src: url('../fonts/globerheavy.otf');
	font-weight: 900;
	font-style: normal;
}
@font-face {
	font-family: 'Glober';
	src: url('../fonts/globerblack.otf');
	font-weight: 700;
	font-style: normal;
}
@font-face {
	font-family: 'Glober';
	src: url('../fonts/globerxbold.otf');
	font-weight: 600;
	font-style: normal;
}
@font-face {
	font-family: 'Glober';
	src: url('../fonts/globerregular.otf');
	font-weight: 400;
	font-style: normal;
}
@font-face {
	font-family: 'Glober';
	src: url('../fonts/globerlight.otf');
	font-weight: 100;
	font-style: normal;
}