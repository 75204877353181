$brand-color = #ca388d
.brand-color 
	color: $brand-color

$font-standard = 21px
$font-small = 12px

$media-min-lg = 1200px
$media-min-md = 992px
$media-min-sm = 768px
$media-min-xs = 576px

$media-max-md = 1199px
$media-max-sm = 991px
$media-max-xs = 767px
$media-max-xxs = 575px