.contact
    background: url("../img/contact.jpg")
    background-repeat: no-repeat
    background-size: cover
    color: #fff

    img
        width: 180px
        margin-top: 80px
        margin-bottom: 30px
        @media (max-width: 767px)
            margin-top: 45px
            margin-bottom: 20px
    p
        font-size: 30px
        @media (max-width: 767px)
            font-size: 18px

    .number
        font-size: 40px
        font-weight: 600
        margin-top: 50px
        @media (max-width: 767px)
            font-size: 22px
            margin-top: 20px