.intro
    background: url("../img/intro.jpg")
    background-repeat: no-repeat
    background-size: cover
    height: calc(100vh - 92px)
    color: #fff
    @media (max-width: 767px)
        height: calc(100vh - 42px)
    @media (max-width: 440px)
        padding: 0
    
    .title
        span 
            color: $brand-color

    .intro-description
        font-size: 30px
        margin: 50px 0 90px 0
        @media (max-width: 767px)
            font-size: 20px
            margin: 30px 0 10px 0
    .intro-products
        padding: 0

        li
            list-style-type: none
            display: inline-block
            @media (max-width: 767px)
                font-size: 12px

            &:after
                content: "|"
                margin-left: 7px
                margin-right: 7px

                @media (max-width: 440px)
                    margin-left: 5px
                    margin-right: 5px

            &:nth-last-child(1)
                &:after
                    display: none
